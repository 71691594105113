<template>
  <v-container>
    <v-responsive>
      <h1 class="ma-6">Übersicht Mitarbeitende</h1>
      <v-row class="ma-2">
        <div class="col-sm-3 custom-col-menu">
          <MainSidebar></MainSidebar>
        </div>
        <div class="col-sm-9 custom-col-main">
          <v-card elevation="1" outlined tile class="pa-2">
            <v-card-text>
              <v-select
                v-model="selectedLocation"
                :items="locations"
                outlined
                label="Standorte auswählen"
                return-object
                item-text="name"
                item-value="name"
                dense
                :disabled="loadingInBackground"
                @change="handleLocationChange"
              >
                <template v-slot:append>
                  <div
                    v-if="loadingInBackground"
                    class="loading-background-info"
                  >
                    <v-progress-circular indeterminate size="24" />
                    <span class="ml-2"
                      >Andere Standorte werden im Hintergrund geladen... Bitte
                      warten</span
                    >
                  </div>
                </template>
              </v-select>
            </v-card-text>
            <v-row v-if="!loading">
              <template v-for="(column, columnIndex) in columns">
                <v-col
                  :cols="getColsForColumn(columnIndex)"
                  :key="columnIndex"
                  v-if="column.length > 0"
                >
                  <v-list>
                    <v-list-item
                      v-for="(person, index) in column"
                      :key="`col-${columnIndex}-person-${index}`"
                      three-line
                    >
                      <div class="d-flex flex-column align-items-center">
                        <v-list-item-avatar class="mt-5 ml-4 mb-0">
                          <v-img :src="person.photo"></v-img>
                        </v-list-item-avatar>
                        <div class="d-flex ma-2">
                          <v-list-item-action
                            class="ma-1"
                            v-if="person.calendar"
                          >
                            <EventCalendar :calendar="person.calendar"></EventCalendar>
                          </v-list-item-action>
                          <v-list-item-action
                            class="ma-1"
                            v-if="person.teamsStatus"
                          >
                            <TeamsStatus
                              v-if="person.teamsStatus.id"
                              :activity="person.teamsStatus.activity"
                              :availability="person.teamsStatus.availability"
                              :status-message="person.teamsStatus.statusMessage"
                              :microsoft-entra-id="person.teamsStatus.id"
                            ></TeamsStatus>
                          </v-list-item-action>
                        </div>
                      </div>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ person.name }} {{ person.surname }} - ({{
                            person.short
                          }})
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <strong>Telefon: </strong>
                          <a :href="getPhoneLink(person.phone)">{{
                            person.phone
                          }}</a>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <strong>E-Mail: </strong>
                          <a :href="getMailtoLink(person.email)">{{
                            person.email
                          }}</a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </template>
            </v-row>
            <v-row v-else>
              <v-col class="d-flex justify-center">
                <v-progress-circular
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script>
import EventCalendar from './EventCalendar.vue'
import TeamsStatus from './TeamsStatus.vue'
import MainSidebar from './MainSidebar.vue'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'PersonOverview',
  components: { MainSidebar, EventCalendar, TeamsStatus },
  computed: {
    ...mapState('persons', [
      'persons',
      'locations',
      'selectedLocation',
      'loading',
      'loadingInBackground',
    ]),
    ...mapGetters('persons', ['filteredPersons']),
    columns() {
      // Filter persons where hide is not true
      const visiblePersons = this.filteredPersons.filter(
        (person) => !person.hide,
      )
      const half = Math.ceil(visiblePersons.length / 2)
      return [visiblePersons.slice(0, half), visiblePersons.slice(half)]
    },
    selectedLocation: {
      get() {
        return this.$store.state.persons.selectedLocation
      },
      set(value) {
        this.$store.commit('persons/SET_SELECTED_LOCATION', value)
      },
    },
  },
  methods: {
    ...mapActions('persons', [
      'fetchDataForLocation',
      'fetchAppointments',
      'fetchTeamsStatus',
    ]),
    handleLocationChange(location) {
      this.fetchDataForLocation({ location: location ? location.name : null })
    },
    getPhoneLink(phone) {
      return `tel:${encodeURIComponent(phone)}`
    },
    getMailtoLink(email) {
      return `mailto:${encodeURIComponent(email)}`
    },
    getColsForColumn() {
      return Math.ceil(12 / this.columns.length)
    },
  },
}
</script>
<style scoped>
.loading-background-info {
  display: flex;
  align-items: center;
}
</style>
