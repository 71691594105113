<template>
  <v-container>
    <v-responsive>
      <h1 class="ma-6">ZKL - Personen</h1>
      <v-row class="ma-2 justify-end">
        <v-btn
          outlined
          @click="syncWithMSEntra"
          v-if="$keycloak.resourceAccess['zkl-api']['roles'].includes('writer')"
          :loading="syncing"
          :disabled="syncing"
        >
          <v-icon v-if="!syncing" left>mdi-sync</v-icon>
          Sync with MS Entra
        </v-btn>
      </v-row>
      <v-row class="ma-2">
        <div class="col-sm-3 custom-col-menu">
          <MainSidebar></MainSidebar>
        </div>
        <div class="col-sm-9 custom-col-main">
          <v-card elevation="1" outlined tile class="pa-2">
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="persons"
                :loading="loading"
              >
              <template v-slot:item="{ item }">
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="!item.deleted_at"
        @click="updateHidePerson(item)"
        :color="item.hide ? 'grey' : 'blue'"
        class="cursor-pointer"
        v-bind="attrs"
        v-on="on"
      >
        {{ item.hide ? 'mdi-eye-off' : 'mdi-eye' }}
      </v-icon>
    </template>
    <span>{{ item.hide ? 'Person anzeigen' : 'Person ausblenden' }}</span>
  </v-tooltip>

  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        @click="deletePerson(item)"
        :color="item.deleted_at ? 'grey' : 'red'"
        :class="{ 'disabled-icon': !!item.deleted_at }"
        class="cursor-pointer"
        :disabled="!!item.deleted_at"
        v-bind="attrs"
        v-on="on"
      >
        mdi-delete
      </v-icon>
    </template>
    <span>{{ item.deleted_at ? 'Person gelöscht' : 'Person löschen' }}</span>
  </v-tooltip>
</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script>
import axios from 'axios'
import MainSidebar from './../MainSidebar.vue'

export default {
  components: { MainSidebar },
  data() {
    return {
      headers: [
        { text: 'Vorname', value: 'name' },
        { text: 'Nachname', value: 'surname' },
        { text: 'Adresse', value: 'address' },
        { text: 'E-Mail', value: 'email' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Aktionen', value: 'actions' },
      ],
      persons: [],
      loading: true,
      syncing: false,
    }
  },
  created() {
    this.fetchPersons()
  },
  methods: {
    async fetchPersons() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_BASE_URL + '/persons/?all=true',
        )
        this.persons = response.data
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    async updateHidePerson(person) {
      try {
        const newHideStatus = !person.hide
        await axios.patch(
          `${process.env.VUE_APP_API_BASE_URL}/persons/${person.id}/hide`,
          {
            hide: newHideStatus,
          },
        )
        // Update the local state
        person.hide = newHideStatus
      } catch (error) {
        console.error('Error updating hide status:', error)
      }
    },

    async deletePerson(person) {
      try {
        await axios.put(
          `${process.env.VUE_APP_API_BASE_URL}/persons/${person.id}/delete`,
        )
      } catch (error) {
        console.error('Error deleting person:', error)
      }
    },

    async syncWithMSEntra() {
      if (this.syncing) return

      this.syncing = true
      try {
        await axios.get(
          process.env.VUE_APP_API_BASE_URL + '/persons/sync-with-ms-entra',
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.syncing = false
      }
    },
  },
}
</script>

<style>
@media (max-width: 600px) {
  #menu {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
